'use client';

import { Fragment, useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEthscriptionBoxContext } from '@/containers/EthscriptionBox/EthscriptionBoxContext';
import { evmService } from '@/services';
import { useEthersSigner } from '@/hooks/useEthersSigner';
import { MenuItem } from './MoreMenuButton';

interface IConfirmWithdraw {
  open: boolean;
  onClose: (flag: MenuItem['value']) => void;
}

const ConfirmWithdraw: React.FC<IConfirmWithdraw> = ({ open, onClose }) => {
  const { ethscription, onChange } = useEthscriptionBoxContext();
  const singer = useEthersSigner();

  async function handleWithdraw() {
    try {
      await evmService.etchMarket.withdrawEthscription({ singer: singer!, ethscription: ethscription! });
      ethscription!.order.isDeposit = false;
      await onChange('update', 'withdraw', ethscription!);
    } catch (error) {
    } finally {
      onClose('withdraw');
    }
  }

  useEffect(() => {
    if (open) {
      handleWithdraw();
    }
  }, [open]);

  return <Fragment />;
};

export default ConfirmWithdraw;
