'use client';

import { Fragment, useEffect } from 'react';
import { useEthscriptionBoxContext } from '@/containers/EthscriptionBox/EthscriptionBoxContext';
import { evmService } from '@/services';
import { useEthersSigner } from '@/hooks/useEthersSigner';
import { MenuItem } from './MoreMenuButton';

interface IComfirmListing {
  open: boolean;
  onClose: (flag: MenuItem['value']) => void;
}
const ComfirmListing: React.FC<IComfirmListing> = ({ open, onClose }) => {
  const { ethscription, onChange } = useEthscriptionBoxContext();
  const singer = useEthersSigner();

  async function handleUnListed() {
    try {
      await evmService.etchMarket.cancelMultipleMakerOrders({ singer: singer!, ethscription: [ethscription!] });
      ethscription!.order.isDeposit = true;
      ethscription!.order.isListing = false;
      await onChange('update', 'unlisting', ethscription!);
    } catch (error) {
    } finally {
      onClose('unlist');
    }
  }

  useEffect(() => {
    if (open) {
      handleUnListed();
    }
  }, [open]);

  return <Fragment />;
};

export default ComfirmListing;
