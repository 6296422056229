'use client';

import { useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { useEthscriptionBoxContext } from '@/containers/EthscriptionBox/EthscriptionBoxContext';
import services, { evmService } from '@/services';
import { useEthersSigner } from '@/hooks/useEthersSigner';

const ConfirmEthscribed = () => {
  const { ethscription } = useEthscriptionBoxContext();
  const singer = useEthersSigner();
  const [isSumbit, setIsSubmit] = useState<boolean>(false);
  const [isEthscribed, setEthscribed] = useState<boolean>(false);

  async function handleEthscribed() {
    try {
      setIsSubmit(true);

      const response = await services.marketplace.getEthscribe({
        collectionName: ethscription?.order.collectionName || '',
        tokenId: ethscription?.order.tokenId || '',
      });

      if (response?.code == 200) {
        await evmService.etchMarket.inscribeEthscription({ singer: singer!, data: response.data.data });
        setEthscribed(true);
      }
    } catch (error) {
      setEthscribed(false);
    } finally {
      setIsSubmit(false);
    }
  }

  return (
    <LoadingButton
      variant="outlined"
      fullWidth
      disableElevation
      loading={isSumbit}
      color="primary"
      disabled={isEthscribed}
      sx={{
        margin: '0 auto',
        height: '36px',
        borderRadius: '46px',
        border: '1px solid #D5E970',
        textTransform: 'none',
        '&:hover': {
          color: '#000',
          bgcolor: 'rgba(229, 255, 101, 1)',
        },
      }}
      onClick={handleEthscribed}
    >
      {isEthscribed ? 'Ethscribed' : 'Ethscribe'}
    </LoadingButton>
  );
};

export default ConfirmEthscribed;
