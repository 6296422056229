'use client';

import { Box } from '@mui/material';
import { useImmer } from 'use-immer';

import ConfirmListing from './ConfirmListing';
import ConfirmUnListing from './ConfirmUnListing';
import ConfirmTransfer from './ConfirmTransfer';
import { useEthscriptionBoxContext } from '../EthscriptionBox/EthscriptionBoxContext';
import ConfirmWithdraw from './ConfirmWithdraw';
import ConfirmEthscribed from './ConfirmEthscribed';
import ConfirmEditListing from './ConfirmEditListing';
import ConfirmStake from './ConfirmStake';
import ConfirmBurn from './ConfirmBurn';
import MoreMenuButton, { MenuItem } from './MoreMenuButton';

const EthscriptionBoxFooter = () => {
  const { ethscription } = useEthscriptionBoxContext();
  const [buttonFlag, setButtonFlag] = useImmer<{ [key in MenuItem['value']]: boolean }>({
    transfer: false,
    withdraw: false,
    stake: false,
    burn: false,
    unlist: false,
  });

  function step() {
    if (ethscription?.order.isUninscribed) {
      return <ConfirmEthscribed />;
    }

    if (ethscription?.order.isListing) {
      return <ConfirmEditListing />;
    }

    // return <ConfirmListing disabled={!Boolean(ethscription?.order.isVerified)} />;
    return <Box />;
  }

  function handleMenuOpen(item: MenuItem) {
    setButtonFlag((state) => {
      state[item.value] = true;
    });
  }

  function handleMenuClose(key: MenuItem['value']) {
    setButtonFlag((state) => {
      state[key] = false;
    });
  }

  return (
    <Box
      sx={{
        p: '16px 0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '10px',
      }}
    >
      {step()}
      <MoreMenuButton onSelectMenu={handleMenuOpen} />

      <ConfirmUnListing open={buttonFlag.unlist} onClose={handleMenuClose} />
      <ConfirmTransfer open={buttonFlag.transfer} onClose={handleMenuClose} />
      <ConfirmWithdraw open={buttonFlag.withdraw} onClose={handleMenuClose} />
      <ConfirmStake open={buttonFlag.stake} onClose={handleMenuClose} />
      <ConfirmBurn open={buttonFlag.burn} onClose={handleMenuClose} />
    </Box>
  );
};

export default EthscriptionBoxFooter;
