import { Box, Button, Typography } from '@mui/material';

import WarnSVG from '@/assets/icons/warn.svg';
import { useConnectModal } from '@rainbow-me/rainbowkit';

interface INotWalletConnect {}

const NotWalletConnect: React.FC<INotWalletConnect> = () => {
  const { openConnectModal } = useConnectModal();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '24px' }}>
      <WarnSVG />
      <Typography>Wallet is disconnected</Typography>
      <Button
        sx={{
          borderRadius: '46px',
          background: '#D5E970',
          display: 'flex',
          padding: ' 10px 16px',
          alignItems: 'flex-start',
          color: '#171A1F',
          fontFamily: 'HarmonyOS Sans',
          fontSize: '14px',
          fontWeight: 500,
          transition: 'all 0.8s ease-in-out',
          '&:hover': {
            fontWeight: '600',
            background: '#D5E970',
          },
        }}
        onClick={openConnectModal}
      >
        Connect Wallet
      </Button>
    </Box>
  );
};

export default NotWalletConnect;
