'use client';

import { Avatar, Box, Checkbox, FormGroup, Link, Typography } from '@mui/material';
import { useAccount, useChainId } from 'wagmi';
import { ethers } from 'ethers';
import { useSnapshot } from 'valtio';
import { useRouter, useSearchParams } from 'next/navigation';

import { GetEthscriptionsItem } from '@/services/marketpalce/types';
import { EthscriptionBoxContext, IEthscriptionBoxContext } from './EthscriptionBoxContext';
import { URL_CONFIG } from '@/constants';
import EthscriptionView from './EthscriptionView';
import * as CartStore from '@/stores/CartStore';
import * as BulkListingStore from '@/stores/BulkListingStore';

interface IEthscriptionBox {
  onChange: IEthscriptionBoxContext['onChange'];
  ethscription: GetEthscriptionsItem;
  footer?: JSX.Element;
  isMyEtchScription?: boolean;
}

const EthscriptionBox: React.FC<IEthscriptionBox> = ({
  onChange,
  ethscription,
  footer = null,
  isMyEtchScription = false,
}) => {
  const { address } = useAccount();
  const chainId = useChainId();

  const router = useRouter();

  const cartStore = useSnapshot(CartStore.store);
  const bulkListingStore = useSnapshot(BulkListingStore.store);

  let addressDisplay = `${ethscription.order.owner.slice(0, 5)}...${ethscription.order.owner.slice(-4)}`;

  if (address && ethers.utils.getAddress(address) === ethers.utils.getAddress(ethscription.order.owner)) {
    addressDisplay = 'you';
  }
  if (
    ethscription.order.protocolAddress.trim() != '' &&
    ethers.utils.getAddress(ethscription.order.owner) === ethers.utils.getAddress(ethscription.order.protocolAddress)
  ) {
    addressDisplay = 'market';
  }

  const isInCart = cartStore.orderIds?.find((orderId) => ethscription.order.orderId === orderId);

  const isInEthlist =
    bulkListingStore.ethsList.findIndex((item) => item.order.ethscriptionId === ethscription.order.ethscriptionId) !==
    -1;

  const isHighlight = Boolean(isInCart) || isInEthlist;

  return (
    <EthscriptionBoxContext.Provider value={{ ethscription, onChange, isInCart: Boolean(isInCart) }}>
      <Box
        sx={{
          width: '100%',
          borderRadius: '8px',
          border: `1px solid ${isHighlight ? '#D5E970' : '#2F343E'}`,
          background: '#202229',
          overflow: 'hidden',
          transition: 'all 0.8s',
          display: 'flex',
          flexDirection: 'column',
          '&:hover': {
            borderColor: '#D5E970',
          },
        }}
      >
        {isMyEtchScription ? (
          // <Link
          //   href={`/market/${ethscription.order.category}?category=${ethscription.order.category}&collectionName=${ethscription.order.collectionName}`}
          //   sx={{
          //     textDecoration: 'none',
          //   }}
          // >
          <Box
            sx={{
              position: 'relative',
              '&:hover': {
                '& .checkbox': {
                  display: 'flex',
                },
              },
            }}
          >
            <EthscriptionView
              slotCheckBox={
                <Box
                  className="checkbox"
                  sx={{
                    display: bulkListingStore.ethsList.length > 0 ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    background: 'rgba(255,255,255,0.1)',
                    width: '32px',
                    height: '32px',
                    borderRadius: '4px',
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    BulkListingStore.toggleEthscriptionToCart({
                      ...ethscription,
                      floorPrice: '',
                      protocol: '0',
                      royalty: '0',
                    });
                  }}
                >
                  <Checkbox
                    size="small"
                    checked={isInEthlist}
                    sx={{
                      '&.MuiCheckbox-root:hover': {
                        bgcolor: 'transparent',
                      },
                    }}
                  />
                </Box>
              }
              ethscription={ethscription}
            />
          </Box>
        ) : (
          <EthscriptionView ethscription={ethscription} />
        )}
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            background: 'rgba(255, 255, 255, 0.05)',
            p: ['token', 'domain'].includes(ethscription.order.category) ? '16px 16px 0 16px' : '0 16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              color: '#fff',
              flex: 1,
              pb: '16px',
            }}
          >
            <Link
              href={`/ethscriptions/${ethscription.order.ethscriptionId}`}
              style={{
                textDecoration: 'none',
              }}
              sx={{ fontSize: '12px', textDecorationLine: 'none', color: '#fff' }}
            >
              {`#${ethscription.order.ethscriptionNumber}`}
            </Link>
            <Link
              target="__blank"
              href={`/tokens/search?address=${ethscription.order.owner}`}
              sx={{ fontSize: '12px', textDecorationLine: 'none', color: '#fff' }}
            >
              {addressDisplay}
            </Link>
          </Box>

          {ethscription.order.isListing && (
            <Box
              sx={{
                pt: '13px',
                display: 'flex',
                justifyContent: 'space-between',
                color: '#fff',
                borderTop: '1px solid rgba(255, 255, 255, 0.10)',
                '& p': {
                  fontSize: '12px',
                },
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar src={ethscription.payment.icon} sx={{ width: 24, height: 24 }} />
                <Typography>{ethscription.order.price}</Typography>
              </Box>
              <Typography sx={{ color: 'rgba(255, 255, 255, 0.45)' }}>{`$${Number(ethscription.order.priceUsd).toFixed(
                2,
              )}`}</Typography>
            </Box>
          )}

          {footer}
        </Box>
      </Box>
    </EthscriptionBoxContext.Provider>
  );
};

export default EthscriptionBox;
