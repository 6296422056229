'use client';

import {
  GetEthPriceData,
  GetEthscriptionsItem,
  GetOrderByCartData,
  GetOrderNonceData,
} from '@/services/marketpalce/types';
import { ethers } from 'ethers';
import { proxy } from 'valtio';

export interface IBulkEthscriptionsItem extends GetEthscriptionsItem {
  floorPrice: string;
  protocol: string;
  royalty: string;
}

export interface IBulkListingStore {
  orderNonceData: GetOrderNonceData | null;
  ethPriceData: GetEthPriceData | null;
  open: boolean;
  cartOrderLoading: boolean;
  openResult: boolean;
  isSuccess: boolean;
  ethsList: IBulkEthscriptionsItem[];
  bulkListEths: IBulkEthscriptionsItem[];
  bulkTransferEths: IBulkEthscriptionsItem[];
  bulkUnlistEths: IBulkEthscriptionsItem[];
  bulkWithdrawEths: IBulkEthscriptionsItem[];
  cartOrder: GetOrderByCartData | null;
  selectAll: boolean;
}

export const store = proxy<IBulkListingStore>({
  orderNonceData: null,
  ethPriceData: null,
  open: false,
  cartOrderLoading: false,
  openResult: false,
  isSuccess: false,
  ethsList: [],
  bulkListEths: [],
  bulkTransferEths: [],
  bulkUnlistEths: [],
  bulkWithdrawEths: [],
  cartOrder: null,
  selectAll: false,
});

export const setIsSuccess = async (isSuccess: boolean) => {
  store.isSuccess = isSuccess;
};

export const setOpenResult = async (openResult: boolean) => {
  store.openResult = openResult;
};

export const setOpen = async (open: boolean) => {
  store.open = open;
  if (open) {
    store.cartOrderLoading = true;
    store.cartOrderLoading = false;
  } else {
    store.cartOrder = null;
  }
};

export const addEthscription = (eths: IBulkEthscriptionsItem) => {
  store.ethsList.push(eths);

  const wagmiStore = JSON.parse(localStorage.getItem('wagmi.store') ?? '');

  // 有效铭文
  if (eths?.order.isVerified) {
    if (eths.order.isListing == false) {
      store.bulkListEths.push(eths);
    }

    if (eths.order.isListing == true) {
      store.bulkUnlistEths.push(eths);
    }

    if (
      eths.order.isListing == false &&
      ethers.utils.getAddress(eths.order.owner) == ethers.utils.getAddress(eths.order.protocolAddress)
    ) {
      store.bulkWithdrawEths.push(eths);
    }
  }

  // 无效铭文，也可以转账
  if (
    eths.order.isListing == false &&
    ethers.utils.getAddress(eths.order.owner) == ethers.utils.getAddress(wagmiStore.state.data.account)
  ) {
    store.bulkTransferEths.push(eths);
  }
};

// 只更新“批量”挂单的数据
export const updateBulkListEths = (eths: IBulkEthscriptionsItem) => {
  store.bulkListEths = store.bulkListEths.map((item) => {
    if (item.order.ethscriptionId === eths.order.ethscriptionId) {
      return eths;
    }
    return item;
  });
};

// 删除 多状态的 批量数据
export const removeBulkEthsciption = (
  eths: IBulkEthscriptionsItem,
  key: 'bulkListEths' | 'bulkTransferEths' | 'bulkUnlistEths' | 'bulkWithdrawEths',
) => {
  let _orderIdIndex = store?.[key].findIndex((item) => item.order.ethscriptionId === eths.order.ethscriptionId);

  if (_orderIdIndex !== -1) {
    store?.[key].splice(_orderIdIndex, 1);
  }
};

// 全家桶铭文, 删除单个数据
export const removeEthscription = (eths: IBulkEthscriptionsItem) => {
  let _orderIdIndex = store.ethsList?.findIndex((item) => item.order.ethscriptionId === eths.order.ethscriptionId);

  const wagmiStore = JSON.parse(localStorage.getItem('wagmi.store') ?? '');

  if (_orderIdIndex !== -1) {
    store.ethsList.splice(_orderIdIndex, 1);
  }

  if (eths.order.isListing == false) {
    removeBulkEthsciption(eths, 'bulkListEths');
  }

  if (eths.order.isListing == true) {
    removeBulkEthsciption(eths, 'bulkUnlistEths');
  }

  if (
    eths.order.isListing == false &&
    ethers.utils.getAddress(eths.order.owner) == ethers.utils.getAddress(wagmiStore.state.data.account)
  ) {
    removeBulkEthsciption(eths, 'bulkTransferEths');
  }

  if (
    eths.order.isListing == false &&
    ethers.utils.getAddress(eths.order.owner) == ethers.utils.getAddress(eths.order.protocolAddress)
  ) {
    removeBulkEthsciption(eths, 'bulkWithdrawEths');
  }
};

// 全家桶铭文数据查找
export const findEthsciption = (eths: IBulkEthscriptionsItem) => {
  return store.ethsList?.find((item) => item.order.ethscriptionId === eths.order.ethscriptionId);
};

// UI 状态, 全部选中
export const setSelectAll = (checked: boolean) => {
  store.selectAll = checked;
};

// 全家桶铭文, 添加、删除 切换
export const toggleEthscriptionToCart = (eths: IBulkEthscriptionsItem) => {
  const _currentOrderId = findEthsciption(eths);
  if (_currentOrderId) {
    removeEthscription(eths);
  } else {
    addEthscription(eths);
  }
};

// 全家桶铭文，全部删除
export const clearAllEthsciption = () => {
  store.ethsList.splice(0, store.ethsList.length);
  store.bulkListEths.splice(0, store.bulkListEths.length);
  store.bulkTransferEths.splice(0, store.bulkTransferEths.length);
  store.bulkUnlistEths.splice(0, store.bulkUnlistEths.length);
  store.bulkWithdrawEths.splice(0, store.bulkWithdrawEths.length);

  store.cartOrder = null;
};

export const setOrderNonceData = (orderNonceData: GetOrderNonceData) => {
  store.orderNonceData = orderNonceData;
};

export const setEthPriceData = (ethPriceData: GetEthPriceData) => {
  store.ethPriceData = ethPriceData;
};
