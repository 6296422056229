'use client';

import { Fragment } from 'react';

import Erc20View from './Erc20View';
import { GetEthscriptionsItem, categoryType } from '@/services/marketpalce/types';

import NftView from './NftView';
import DomainView from './DomainView';

interface IEthsLabelCard {
  category: categoryType;
  ethscription: GetEthscriptionsItem;
}

const EthsLabelCard: React.FC<IEthsLabelCard> = ({ category, ethscription }) => {
  const EthscriptionViews: { [key: string]: JSX.Element } = {
    token: <Erc20View ethscription={ethscription} />,
    domain: <DomainView ethscription={ethscription} />,
    nft: <NftView ethscription={ethscription} />,
  };

  return EthscriptionViews[category] ?? null;
};

export default EthsLabelCard;
