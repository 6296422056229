'use client';

import { useMediaQuery } from '@mui/material';

import DetailBack from './DetailBack';
import MyEthscriptions from '@/containers/MyEthscriptions';
import BulkOperation from '@/containers/MyEthscriptions/BulkOperation';
import BulkOperationH5 from '@/containers/MyEthscriptions/BulkOperationH5';
import OwnerLayout from './layout';

const EthscriptionsPage = () => {
  const matches = useMediaQuery('(min-width:750px)');
  return (
    <OwnerLayout>
      {/* <DetailBack label="My Ethscriptions" /> */}
      <MyEthscriptions />
      {matches ? <BulkOperation /> : <BulkOperationH5 />}
    </OwnerLayout>
  );
};

export default EthscriptionsPage;
