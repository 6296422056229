'use client';

// import Home from './home';
import { useAccount } from 'wagmi';
import Owner from './owner';
import { Box, Typography } from '@mui/material';

const Home = () => {
  const { address } = useAccount();

  if (address) {
    return <Owner />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
      }}
    >
      <Typography sx={{ textAlign: 'center' }}>
        Please withdraw your inscription as soon as possible via the "My Ethscriptions" page. <br /> You can also
        withdraw your inscription permissionlessly from the contract at
        <a
          style={{ color: 'yellow', marginLeft: '5px' }}
          target="_blank"
          href="https://etherscan.io/address/0x57b8792c775d34aa96092400983c3e112fcbc296#writeProxyContract#F14"
        >
          Etherscan
        </a>
      </Typography>
      <img src="/images/home_info.jpg" alt="Etch Logo" style={{ width: '70%', marginTop: '50px' }} />
    </Box>
  );
};

export default Home;
