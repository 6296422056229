import {
  GetCollectionOwnerListData,
  GetCollectionOwnerListItem,
  GetCollectionOwnerListRequest,
} from '@/services/marketpalce/types';
import { createContext, useContext } from 'react';
import { Updater } from 'use-immer';

export interface IEthscriptionCollectionOwnerContext {
  onSelect: (val: GetCollectionOwnerListItem) => Promise<void>;
  collectionItem: GetCollectionOwnerListItem | null;
  setCollectionItem: (val: GetCollectionOwnerListItem) => void;
  collectionList: GetCollectionOwnerListData | null;
  isLoading: boolean;
  setFilterRequest: Updater<GetCollectionOwnerListRequest>;
}

export const EthscriptionCollectionOwnerContext = createContext<IEthscriptionCollectionOwnerContext>({
  onSelect: async () => {},
  collectionItem: null,
  setCollectionItem: () => {},
  collectionList: null,
  isLoading: false,
  setFilterRequest: () => {},
});

export const useEthscriptionCollectionOwnerContext = () => useContext(EthscriptionCollectionOwnerContext);
