import { GetEthscriptionsItem } from '@/services/marketpalce/types';
import { createContext, useContext } from 'react';

export interface IEthscriptionBoxContext {
  ethscription: GetEthscriptionsItem | null;
  isInCart: boolean;
  onChange: (
    op: 'update' | 'remove',
    action:
      | 'listing'
      | 'edit-listing'
      | 'transfer'
      | 'unlisting'
      | 'withdraw'
      | 'ethscribed'
      | 'buy'
      | 'stake'
      | 'redeem'
      | 'burn',
    val: GetEthscriptionsItem,
  ) => Promise<void>;
}

export const EthscriptionBoxContext = createContext<IEthscriptionBoxContext>({
  ethscription: null,
  isInCart: false,
  onChange: async () => {},
});

export const useEthscriptionBoxContext = () => useContext(EthscriptionBoxContext);
